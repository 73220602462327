import { default as index65RKfLbkpAMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom/index.vue?macro=true";
import { default as packageskc4Uaj25lUMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom/packages.vue?macro=true";
import { default as partswZxeszhlWfMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom/parts.vue?macro=true";
import { default as customslsIlptNo8Meta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom.vue?macro=true";
import { default as indexgQPP5sB83tMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/index.vue?macro=true";
import { default as ratesyWY41yj7zFMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/rates.vue?macro=true";
import { default as subscriptionbG0MfsZhpvMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/subscription.vue?macro=true";
import { default as accountiJk8GsOWidMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account.vue?macro=true";
import { default as _91id_93RTldP9v6vWMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexuwRTHWy6KeMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_936HPyaPHOAEMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93r5Q8lOaClGMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as indexk67Tp1sJvRMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesKFRK1m1wRUMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93jP6oQNoDJ1Meta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/boutique/[title]-[id].vue?macro=true";
import { default as indexyTo9YQqlmkMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/boutique/index.vue?macro=true";
import { default as productsEhIMKsJqLQMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/boutique/products.vue?macro=true";
import { default as indexLtZ93cMN8cMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/index.vue?macro=true";
import { default as configurationEuevAjnhekMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexlULY3BLKcxMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planCPHMmkJ3ChMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksaZHrQ3TC6lMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenancemlIh1jMXGLMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manualshyjcAdsnvKMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/manuals.vue?macro=true";
import { default as timesFEJxI12DyFMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/times.vue?macro=true";
import { default as catalog2J580ihdA1Meta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog.vue?macro=true";
import { default as commande1lHOkReJCBMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/commande.vue?macro=true";
import { default as _91tab_93ahirLH0XXuMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93xwXKHugufCMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93PUmPJTfCrdMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfS2h0LaF8xEMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexX7tjGmvJ7KMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/index.vue?macro=true";
import { default as login13Wn1nUiUrMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/login.vue?macro=true";
import { default as panier2cL2IHXhQFMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/panier.vue?macro=true";
import { default as indexmnXqag97yYMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/pneumatique/index.vue?macro=true";
import { default as productsiycwWF7hs9Meta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatique5xQXWpEzaZMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/pneumatique.vue?macro=true";
import { default as maintenanceAJI5Fq1QLTMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/print/maintenance.vue?macro=true";
import { default as productsstF6DFuy3rMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/products.vue?macro=true";
import { default as signupDAALGwgYqqMeta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordj65bJHVyT4Meta } from "/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountiJk8GsOWidMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account.vue"),
    children: [
  {
    name: customslsIlptNo8Meta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/boutique/products.vue")
  },
  {
    name: catalog2J580ihdA1Meta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/index.vue")
  },
  {
    name: maintenancemlIh1jMXGLMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfS2h0LaF8xEMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login13Wn1nUiUrMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/panier.vue")
  },
  {
    name: pneumatique5xQXWpEzaZMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceAJI5Fq1QLTMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupDAALGwgYqqMeta || {},
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/equipautopro.fr/dev-app.equipautopro.fr/pages/tableau-de-bord.vue")
  }
]